import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import "./hero.css";
import { Button } from "../ui/moving-border.tsx";
import { TypewriterEffect } from "../ui/typewriter-effect";

import { TextGenerateEffect } from "../ui/text-generate-effect";

const Hero = () => {
  return (
    <div className="hero-section ">
      <Row>
        <Col span={12}>
        <TypewriterEffect  words={Header} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TextGenerateEffect words={words} />
        </Col>
      </Row>
      <Link to="/contactUs">
      <Button
        borderRadius="1.75rem"
        className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
      >
        Get Started
      </Button>
      </Link>
    </div>
  );
};
export default Hero;



  const Header = [
    {
      text: "LET'S",
    },
    {
      text: "BEGIN",
    },
    {
      text: "YOUR",
    },
    {
      text: "HEALTHIEST",
    },
    {
      text: "JOURNEY",
    },
    {
      text: "TOGETHER.",
      className: "text-blue-500 dark:text-blue-500",
    },
  ];



  const words = ` Discover the power of natural supplements designed to support your health and well-being. Our carefully curated selection is crafted to enhance your lifestyle and elevate your vitality.`;
