import React from "react";
import { Card, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTablets,
  faBolt,
  faHandshake,
  faTruck,
  } from "@fortawesome/free-solid-svg-icons";
import { GlareCard } from "../ui/glare-card";

const ServiceCard = ({ title, description, icon }) => (
  <Col xs={24} sm={12} md={6} lg={6}>
    <GlareCard className="flex flex-col items-center justify-center" >
        
    <Card title={title} bordered={false} className="service-card" >
      <div className="service-icon ">
        <FontAwesomeIcon icon={icon} size={"3x"} />
      </div>
      <p>{description}</p>
    </Card>
    </GlareCard>

  </Col>
);

const Services = () => (
  <div className="services" style={{ marginTop: "20px" }}>
    <h1 className="m-y-10, font-extrabold" >Our Services</h1>
    <Row gutter={[16, 16]}>
      <ServiceCard
        icon={faTablets}
        title="High Quality Supplements"
        description="We offer a wide range of high quality supplements to choose from."
      />
      <ServiceCard
        title="Wide Supplements Range"
        description="Our supplements range includes a variety of shakes and capsules."
        icon={faBolt}
      />
      <ServiceCard
        title="Excellent Services"
        description="We provide excellent customer service and support."
        icon={faHandshake}
      />
      <ServiceCard
        title="Fast Delivery"
        description="We offer fast and reliable delivery service across UK."
        icon={faTruck}
      />
    </Row>

 
  </div>
);

export default Services;


