import React, { useState, useEffect } from "react";
import axios from "axios";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

const items = [
  {
    key: "1",
    label: (
      <a
        target="_self"
        rel="noopener noreferrer"
        href="https://www.divinewellbeing.co.uk/product/type/proteins"
      >
        Protein
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_self"
        rel="noopener noreferrer"
        href="https://www.divinewellbeing.co.uk/product/type/essentialvitamins"
      >
        Essential Vitamins
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a target="_self" rel="noopener noreferrer" href="../">
        Gels and Spray
      </a>
    ),
  },
];
const Getdata = () => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FETCH_PRODUCT}`
        ); // Replace with your backend API endpoint
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching options: ", error);
      }
    };

    fetchData();
  }, []);

  {
    items.map((item) => {});
  }
};

const DropDown = () => (
  <Dropdown
    menu={{
      items,
    }}
  >
    <a onClick={(Getdata) => Getdata.preventDefault()}>
      <Space>
        Shop
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
);
export default DropDown;
