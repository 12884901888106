import { Button } from "antd";
import axios from "../../lib/axios";

export default function Mollie() {
  const handlePayment = async (e) => {
    await axios
      .get("/pay")
      .then((res) => {
        const { href } = res.data.payment._links.checkout;
        // console.log(href);
        window.location = href;
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <Button onClick={handlePayment}>Pay</Button>
    </div>
  );
}
