import React from "react";
import { Link } from "react-router-dom";
import CopyRight from "../../Components/CopyRight/CopyRight";

const NotFoundPage = () => {
  return (
    <>
      <div
        className="container"
        style={{
          textAlign: "center",
          marginTop: "50px",
          alignContent: "center",
        }}
      >
        <h1 style={{ color: "red" }}>404 - Not Found</h1>
        <p>The page you are looking for might be in another castle...</p>
        <Link to="/" style={linkStyle}>
          Go Home
        </Link>
      </div>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};
const linkStyle = {
  textDecoration: "none",
  color: "#3498db",
  fontWeight: "bold",
  margin: "10px",
};
export default NotFoundPage;
