import React from "react";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Title from "antd/es/typography//Title";
import Paragraph from "antd/es/typography/Paragraph";

function Return() {
  return (
    <div>
      <Title>Privacy Policy</Title>
      <Paragraph>
        <p>
          We appreciate the trust and loyalty that you have shown in us by doing
          business with Divine Wellbeing LTd. We are committed to offer best
          service and satisfaction from our end. We pride ourselves in offering
          you the finest health and wellness products. In the event of return or
          exchange of the product, please refer important information as below.
        </p>
        <p>
          1. You have a statutory right to cancel the order within 14 days of
          receipt of the goods.
        </p>
        <p>
          2. The full cost of the order cancelled including the cost of postage
          will be refunded if the order has been cancelled within the
          customer&rsquo;s statutory right to cancel within 14 days.
        </p>
        <p>
          3. The customer must not delay the return of goods and should send
          them back within 14 days of when he informed us of his decision to
          cancel. The cost of returning the goods after cancellation will be
          borne by the customer unless it&rsquo;s a defective product or
          products sent in error.
        </p>
        <p>
          4. We would appreciate if you could return the parcel by recorded
          delivery and keep the receipt incase its required.
        </p>
        <p>
          5. If any supplements from the boxes have been consumed then the
          refund will be applicable to the unused boxes unless it is within the
          customer&rsquo;s statutory right to cancel within 14 days.
        </p>
        <p>
          6. If incase the customer requires additional services such as gift
          wrapping or express delivery, the cost of these additional services
          will not be refunded in the event of cancellation of the order.
        </p>
        <p>
          7. The customer can inspect the product but at the same time ensure
          that no damage is caused to the product and reasonable care is taken
          of the packaging when returning the product.
        </p>
        <p>
          8. The amount to be refunded to the consumer will be done using the
          same payment method he used originally but we can come to an agreement
          with the customer to use an alternative method.
        </p>
        <p>To return your goods, please use this address:</p>
        <p>
          Divine Wellbeing Ltd;- 71-75 Shelton Street, Covent Garden, London,
          United Kingdom, WC2H 9JQ
        </p>
        <p>
          In event of returning the product, please call the Customer Service
          Team on P89Number, (Monday to Friday between 9am and 4pm) or email at{" "}
          <a
            data-fr-linked="true"
            href="mailto:customercare@divinewellbeing.co.uk"
          >
            customercare@divinewellbeing.co.uk
          </a>{" "}
          for instructions on returning the parcel. We recommend all returns be
          sent via recorded delivery.
        </p>
        <p>
          &nbsp;AS SOON AS WE RECEIVE THE RETURNED UNOPENED ITEM(S), WE WILL
          REFUND THE RELEVANT PURCHASE PRICE FOR THAT ITEM. HOWEVER, IF THERE IS
          ANY LOSS OR DAMAGE TO THE GOODS IN TRANSIT, AFTER THE CUSTOMER HAS
          POSTED IT OUT, WE WILL STILL REFUND THE AMOUNT, PROVIDED THE CUSTOMER
          HAS PROOF OF DELIVERY. WE WILL REFUND ANY MONEY RECEIVED FROM THE
          CUSTOMER FOR THE PRODUCTS USING THE SAME METHOD ORIGINALLY USED TO PAY
          FOR THE PRODUCTS BUT WE CAN COME TO AN AGREEMENT WITH THE CONSUMER TO
          USE AN ALTERNATIVE METHOD. THE AMOUNT WE WILL REFUND WILL BE THE
          PRODUCT VALUE PLUS POSTAGE AND ANY DISCOUNT ONLY IF IT&rsquo;S WITHIN
          THE CUSTOMER&rsquo;S STATUTORY RIGHT TO CANCEL WITHIN 14 DAYS, WHICH
          HAS BEEN DIVIDED PROPORTIONATELY ACROSS THE TOTAL VALUE OF THE
          ORIGINAL ORDER. WE WILL PROCESS THE REFUND DUE AS SOON AS POSSIBLE AND
          IN ANY EVENT, WITHIN 14 DAYS AFTER WE RECEIVE THE RETURNED PARCEL OR
          WE RECEIVE THE PROOF FROM YOU THAT YOU HAVE SENT THE GOODS BACK.
        </p>
        <p>
          In an event where the customer requests for products to be exchanged,
          or replaced, then the customer will have to pay an additional delivery
          charge to have such exchange or replacement products sent.
        </p>
        <p>RETURN AND REFUND POLICY</p>
        <p>
          The refund will be given to the consumer without undue delay.
          &nbsp;Once we receive the returned goods, we will reimburse within 14
          days from the day we get the goods back or 14 days from the day we
          receive proof from the consumer that he has sent the goods back. We
          will reimburse the consumer using the same payment method he used
          originally but we can come to an agreement with the consumer to use an
          alternative method.
        </p>
        <p>
          In a scenario where the customer requests for the parcel to be
          collected from their residence, in this case the customer will have to
          bear the relevant cost of collecting the parcel.
        </p>
        <p>RETURN OF FAULTY PRODUCTS OR PRODUCTS SENT IN ERROR</p>
        <p>
          You have the full right to return defective products or products sent
          in error for a full refund and cost of returning the parcel will be
          borne by Regen Healthcare
        </p>
        <p>CONSUMER CONTRACTS UUNDER THE DISTANCE SELLING REGULATIONS</p>
        <p>Right to cancel</p>
        <p>
          You have the right to cancel this contract within 14 days from the day
          on which the goods came into your physical possession.
        </p>
        <p>
          The cancellation period will expire after 14 days from the day on
          which the goods came into your physical possession.
        </p>
        <p>
          To exercise the right to cancel, you must inform us, by contacting us
          at Regen Healthcare Limited (our contact details are set out below),
          of your decision to cancel this contract by a clear statement (e.g. a
          letter sent by post fax or email). You may use the attached model
          cancellation form, but it is not obligatory.
        </p>
        <p>Telephone : 7385667826</p>
        <p>Warehouse Address</p>
        <p>71-75 Shelton Street, </p>
        <p> Covent Garden,</p>
        <p>London,</p>
        <p>United Kingdom,</p>
        <p>WC2H 9JQ</p>
        <p>
          <br />
        </p>
        <p>
          If you have your reference number, please include this when you
          contact us so we can respond in the fastest possible time.
        </p>
        <p>
          To meet the cancellation deadline, it is sufficient for you to send
          your communication concerning your exercise of the right to cancel
          before the cancellation period has expired.
        </p>
        <p>EFFECTS OF CANCELLATION WITHIN 14 DAYS</p>
        <p>
          You may be entitled to a refund on cancellation in 14 days, please
          refer to our Refunds Policy
        </p>
        <p>
          <br />
        </p>
        <p>MODEL CANCELLATION FORM</p>
        <p>
          To: Divine Wellbeing Ltd:- 71-75 Shelton Street, Covent Garden,
          London, United Kingdom, WC2H 9JQ
        </p>
        <p>
          <br />
        </p>
        <p>
          I hereby give notice that I cancel my contract of sale for the supply
          of the following service from Divine Wellbeing Limited.
        </p>
        <p>
          Order No: ______________ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Received on: ______________
        </p>
        <p>
          Name of
          consumer:________________________________________________________________________________
        </p>
        <p>
          Address of
          consumer:________________________________________________________________________________
        </p>
        <p>
          Signature of consumer:__________________&nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; Date:_________________
        </p>
      </Paragraph>
      <CopyRight />
    </div>
  );
}

export default Return;
