import { Typography } from "@mui/material";

import "./footer.css";
import React from "react";
import Logo from "../../Assets/Images/logo192.png";
import { Link } from "react-router-dom";
import Title from "antd/es/typography//Title";

const CopyRight = (props) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={Logo} alt="Company logo" width={100} />
        </div>
        <div className="footer-contact-info">
          <p style={{color: "#ffff", fontWeight:"bolder"}}>Phone: </p>
          <p>
            Email: <br />
            <a href="mailto:customercare@divinewellbeing.co.uk"style={{color: "#ffff", fontWeight:"bolder"}}>
              customercare@divinewellbeing.co.uk
            </a>
          </p>
          <p>
            Address:
            <br />
            <b style={{color: "#ffff", fontWeight:"bolder"}}>
              71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H
              9JQ
            </b>
          </p>
        </div>
        <div className="footer-policy">
          <Title style={{color: "#ffff", fontWeight:"bolder"}}>Customer Support</Title>
          <ul style={{listStyle: "none"}}>
            <li>
              <Link to="/shipment">Shipment Policy</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/return">Return & Refund</Link>
            </li>
            <li>
              <Link to="/terms">Terms And Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <a href="" target="_blank" rel="noreferrer">
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.secondary"
            align="center"
            {...props}
            style={{ color: "#1976d2" }}
          >
            {" "}
            {new Date().getFullYear()}
            {/* {'.'} */}
            {" © "}
            Divine Wellbeing
          </Typography>
        </a>
      </div>
    </footer>
  );
};

export default CopyRight;
