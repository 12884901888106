import React, { useEffect } from "react";
import axios from "axios";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { ContextFunction } from "../../Context/Context";
import CategoryCard from "../../Components/Category_Card/CategoryCard";
import BannerData from "../../Helpers/HomePageBanner";

import SearchBar from "../../Components/SearchBar/SearchBar";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Hero from "../../Components/hero/hero";
import Services from "../../Components/ServiceCard/Service";
import ContentWithImage from "../../Components/Image_content/content";
import FeaturedProducts from "../../Components/Featured_product/FeaturedProducts";

const HomePage = () => {
  const { setCart } = useContext(ContextFunction);
  let authToken = localStorage.getItem("Authorization");
  useEffect(() => {
    getCart();
    window.scroll(0, 0);
  }, []);
  const getCart = async () => {
    if (authToken !== null) {
      const { data } = await axios.get(`${process.env.REACT_APP_GET_CART}`, {
        headers: {
          Authorization: authToken,
        },
      });
      setCart(data);
    }
  };

  return (
    <>
      <Container
        maxWidth="xl"
        
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
          flexDirection: "column",
          marginBottom: 70,
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Hero />
  
        <Services />

        <Container
          style={{ marginTop: 30, display: "flex", justifyContent: "center" }}
        >
          <SearchBar />
        </Container>

        <FeaturedProducts />
        
        <Container
          maxWidth="xl"
          style={{
            marginTop: 90,
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          {BannerData.map((data) => (
            <CategoryCard data={data} key={data.img} />
          ))}

          
          </Container >
            <ContentWithImage />


          <CopyRight sx={{ mt: 8  }} />
        </Container>
    </>
  );
};

export default HomePage;
