import React from "react";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Title from "antd/es/typography//Title";
import Paragraph from "antd/es/typography/Paragraph";

function Terms() {
  return (
    <div>
      <Title>Terms & Conditions</Title>
      <Paragraph>
        <p>
          If you continue to browse and use this website, you are agreeing to
          comply with and be bound by the following terms and conditions of use,
          which together with our Privacy Policy govern by Divine Wellebing
          Limited relationship with you in relation to this website. If you
          disagree with any part of these terms and conditions, please do not
          use our website.
          <br />
          The use of this website is subject to the following terms of use:
          <br />
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
          <br />
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
          <br />
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, all text, images,
          illustrations, files, design, script, layout, look, appearance,
          graphics, photos, sounds, music, videos, information, content,
          materials, products, services URL’s, technology, documentation and
          interactive features (collectively “website content”). Reproduction is
          prohibited other than in accordance with the copyright notice, which
          forms part of these terms and conditions.
          <br />
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
          <br />
          Unauthorised use of this website may give rise to a claim for damages
          and/or be a criminal offence.
          <br />
          Site Up Time
          <br />
          All reasonable measures are taken by us to ensure that this Website is
          operational all day, every day. However, occasionally technical issues
          may result in some downtime and accordingly we will not be liable if
          this website is unavailable at any time.
          <br />
          All reasonable measures are taken by us to ensure that this website as
          accessible as possible with compatibility for all software and devices
          (mobile, tablet and otherwise). Please note that there may be times
          when our system may not be able to meet your technical requirements
          and the demands placed on the server due to the volume of visitors.
          Where possible we always try to resolve your technical issues and give
          advance warning of maintenance issues that may result in Website down
          time but we shall not be obliged to provide such notice.
          <br />
          Visitor Provide Material
          <br />
          1. Any material that a visitor to this Website sends or posts to this
          Website shall be considered non-proprietary and non confidential. We
          shall be entitled to copy, disclose, distribute or use for such other
          purpose as we deem appropriate all material provided to us, with the
          exception of personal information, the use of which is covered under
          our Privacy Policy.
          <br />
          2. When using this website you shall not post or send to or from this
          Website any material:
          <br />
          for which you have not obtained all necessary consents that is
          discriminatory, obscene, pornographic, defamatory, liable to incite
          racial hatred, in breach of confidentiality or privacy, which may
          cause annoyance or inconvenience to others, which encourages or
          constitutes conduct that would be deemed a criminal offence, give rise
          to a civil liability, or otherwise is contrary to the law in the
          United Kingdom;
          <br />
          which is harmful in nature including, and without limitation, computer
          viruses, Trojan horses, corrupted data, or other potentially harmful
          software or data. We will fully co-operate with any law enforcement
          authorities or court order requiring us to disclose the identity or
          other details of any person posting material to this website in breach
          these terms.
          <br />
          Links to & from Other Websites
          <br />
          Throughout this Website you may find links to third party websites.
          These links are provided for your convenience to provide further
          information. The provision of a link to such a website does not mean
          that we endorse that website. If you visit any website via a link on
          this Website you do so at your own risk. We have no responsibility for
          the content of the linked website.
          <br />
          Any party wishing to link to this website is entitled to do so
          provided that the conditions below are observed: you do not seek to
          imply that we are endorsing the services or products of another party
          unless this has been agreed with us in writing; you do not
          misrepresent your relationship with this website; and the website from
          which you link to this Website does not contain offensive or otherwise
          controversial content or, content that infringes any intellectual
          property rights or other rights of a third party.
          <br />
          By linking to this Website in breach of the clause above you shall
          indemnify us for any loss or damage suffered to this Website as a
          result of such linking.
          <br />
          Registration
          <br />
          a) In order to register for the Site, you will be asked to give
          Personal Information including but not limited to your name, date of
          birth and place of residence. You expressly acknowledge that we are
          relying on the information you have specified and agree that this
          information is true and accurate.
          <br />
          b) When you register, you will choose a password (which you will be
          able to change) for access to functions within the Site. You agree to
          keep your password secure, and not to allow anyone other than yourself
          access to the Site or to your password.
          <br />
          Your Personal Information
          <br />
          a) We will not provide any of your information to third parties
          without your prior consent.
          <br />
          b) We will use the location associated with your submitted Content
          publicly on the site.
          <br />
          c) We may use your name, age, username, image and location publicly on
          the site in the form of credit for your submitted Content.
          <br />
          d) We may use the information that we collect from you, including but
          not limited to your email address, to provide information to you
          relating to our products.
          <br />
          e) You agree that any information you provide is accurate, current and
          true
          <br />
          Data Protection & Privacy
          <br />
          We will only use any personal information that we may collect about
          you in accordance with our privacy policy.
          <br />
          Disclaimer
          <br />
          Whilst we do take all reasonable steps to make sure that the
          information on this website is up to date and accurate at all times we
          do not guarantee that all material is accurate and, or up to date.
          <br />
          All material contained on this Website is provided without any or
          warranty of any kind. You use the material on this Website at your own
          discretion.
          <br />
          Cookies
          <br />
          A Cookie is a piece of information that our web server may send to
          your machine when you visit a Divine Wellbeing’s site. A Cookie helps
          us to recognize you when you re-visit our sites and to co-ordinate
          your access to different pages on the sites. With most Internet
          Browsers, you can erase Cookies from your computer hard drive, block
          all Cookies, or receive a warning before a Cookie is stored. If you
          want to do this, refer to your Browser instructions or help screen to
          learn more. If you disable all cookies, you may not be able to take
          advantage of all the features of our sites.
          <br />
          Exclusion of Laibility
          <br />
          We do not accept liability for any loss or damage that you suffer as a
          result of using this Website.
          <br />
          Nothing in these Terms of Use shall exclude or limit liability for
          death or personal injury caused by negligence which cannot be excluded
          or under the law of the United Kingdom.
          <br />
          Governing Overning Law & Jurisdiction
          <br />
          These terms of use are governed by the laws of England and Wales and
          the parties agree to submit to the exclusive jurisdiction of the
          English Courts.
          <br />
          OUR DETAILS
          <br />
          Company Name:
          <br />
          Divine Wellbeing Ltd.
          <br />
          Company Address:
          <br />
          71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
          <br />
          Phone: 020 45247956
          <br />
          <br />
          Email:
          <br />
          <a href="mailto:customercare@divinewellbeing.co.uk">
            customercare@divinewellbeing.co.uk
          </a>
          <br />
          Registered in England and Wales: 15430163
          <br />
          WE DO NOT STORE CARD DETAILS NOR DO WE SHARE CUSTOMER DETAILS WITH ANY
          3RD PARTIES
        </p>
      </Paragraph>
      <CopyRight />
    </div>
  );
}

export default Terms;
