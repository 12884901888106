import React from "react";
import { Typography } from "antd";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Title from "antd/es/typography//Title";
import Paragraph from "antd/es/typography/Paragraph";

function Shipment() {
  return (
    <div>
      <Typography>
        <Title>Shipment Policy</Title>
        <Paragraph>
          Our standard delivery charge is £3.95 (incl. VAT) to anywhere in the
          UK, excluding Northern Ireland, Channel Islands, Scottish islands and
          the Isle of Man, for maximum weight of 500gm. Packages weighing from
          501gm to 1000gm will be charged £4.99. For next day delivery, the
          customer will be charged £7.95 for weight up to 500gm. Weight more
          than 500gm will be charged £14.95.
        </Paragraph>
        <Paragraph>
          We are committed to offering excellent value and customer service to
          our customers, including free UK standard delivery (via Royal Mail)
          with any online order worth £30 or more. We strive to get your package
          to you as quickly as possible, but please allow up to 7 to 14 days for
          delivery. Should you require expedited delivery service for an
          additional fee, please contact our Customer Service team on +97NUMBER
        </Paragraph>
        <Paragraph>
          Please note that restrictions may apply in many territories. It is
          your responsibility to check with the authorities in your country
          before placing orders for products, if you are unsure as to their
          status. We are not liable for orders that face any customs issues. In
          case the order is returned to us by customs, a refund will be issued,
          deducting any postage charges. In case the order is destroyed by
          customs, we are not liable to issue a refund/replacement.
        </Paragraph>
      </Typography>
      <CopyRight />
    </div>
  );
}

export default Shipment;
