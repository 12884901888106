import React from "react";
import { Typography } from "antd";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Title from "antd/es/typography//Title";
import Paragraph from "antd/es/typography/Paragraph";

function Privacy() {
  return (
    <div>
      <Title>Privacy Policy</Title>
      <Paragraph>
        <p>
          This Privacy Policy governs the manner in which our company collects,
          uses, maintains and discloses information collected from users (each,
          a &ldquo;User&rdquo;) of the{" "}
          <a data-fr-linked="true" href="https://divinewellbeing.co.uk/">
            https://divinewellbeing.co.uk/
          </a>{" "}
          website (&ldquo;Site&rdquo;). This privacy policy applies to the Site
          and all products and services offered by our company.
        </p>
        <p>
          <br />
        </p>
        <p>Personal identification information</p>
        <p>
          <br />
        </p>
        <p>
          We may collect personal identification information from Users in a
          variety of ways, including, but not limited to, when Users visit our
          site, register on the site, place an order, subscribe to the
          newsletter and in connection with other activities, services, features
          or resources we make available on our Site. Users may be asked for, as
          appropriate, name, email address, mailing address, phone number,
          credit card information,
        </p>
        <p>
          <br />
        </p>
        <p>
          We will collect personal identification information from Users only if
          they voluntarily submit such information to us. Users can always
          refuse to supply personally identification information, except that it
          may prevent them from engaging in certain Site related activities.
        </p>
        <p>
          <br />
        </p>
        <p>Non-personal identification information</p>
        <p>
          <br />
        </p>
        <p>
          We may collect non-personal identification information about Users
          whenever they interact with our Site. Non-personal identification
          information may include the browser name, the type of computer and
          technical information about Users means of connection to our Site,
          such as the operating system and the Internet service providers
          utilized and other similar information.
        </p>
        <p>
          <br />
        </p>
        <p>Web browser cookies</p>
        <p>
          <br />
        </p>
        <p>
          Our Site may use &ldquo;cookies&rdquo; to enhance User experience.
          User&rsquo;s web browser places cookies on their hard drive for
          record-keeping purposes and sometimes to track information about them.
          User may choose to set their web browser to refuse cookies, or to
          alert you when cookies are being sent. If they do so, note that some
          parts of the Site may not function properly.
        </p>
        <p>
          <br />
        </p>
        <p>How we use collected information</p>
        <p>
          <br />
        </p>
        <p>
          our company collects and uses Users personal information for the
          following purposes:
        </p>
        <p>
          <br />
        </p>
        <p>To improve customer service</p>
        <p>
          <br />
        </p>
        <p>
          Your information helps us to more effectively respond to your customer
          service requests and support needs.
        </p>
        <p>
          <br />
        </p>
        <p>To personalize user experience</p>
        <p>
          <br />
        </p>
        <p>
          We may use information in the aggregate to understand how our Users as
          a group use the services and resources provided on our Site.
        </p>
        <p>
          <br />
        </p>
        <p>To improve our Site</p>
        <p>
          <br />
        </p>
        <p>
          We continually strive to improve our website offerings based on the
          information and feedback we receive from you.
        </p>
        <p>
          <br />
        </p>
        <p>To process transactions</p>
        <p>
          <br />
        </p>
        <p>
          We may use the information Users provide about themselves when placing
          an order only to provide service to that order. We do not share this
          information with outside parties except to the extent necessary to
          provide the service.
        </p>
        <p>
          <br />
        </p>
        <p>To administer a content, promotion, survey or other Site feature</p>
        <p>
          <br />
        </p>
        <p>
          To send Users information they agreed to receive about topics we think
          will be of interest to them.
        </p>
        <p>
          <br />
        </p>
        <p>To send periodic emails</p>
        <p>
          <br />
        </p>
        <p>
          The email address Users provide for order processing, will only be
          used to send them information and updates pertaining to their order.
          It may also be used to respond to their inquiries, and/or other
          requests or questions. If User decides to opt-in to our mailing list,
          they will receive emails that may include company news, updates,
          related product or service information, etc. If at any time the User
          would like to unsubscribe from receiving future emails, we include
          detailed unsubscribe instructions at the bottom of each email or User
          may contact us via our Site.
        </p>
        <p>
          <br />
        </p>
        <p>How we protect your information</p>
        <p>
          <br />
        </p>
        <p>
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information and data stored on our
          Site.
        </p>
        <p>
          <br />
        </p>
        <p>
          Sensitive and private data exchange between the Site and its Users
          happens over a SSL secured communication channel and is encrypted and
          protected with digital signatures. Our Site is also in compliance with
          PCI vulnerability standards in order to create as secure of an
          environment as possible for Users.
        </p>
        <p>
          <br />
        </p>
        <p>Sharing your personal information</p>
        <p>
          <br />
        </p>
        <p>
          We do not sell, trade, or rent Users personal identification
          information to others. We may share generic aggregated demographic
          information not linked to any personal identification information
          regarding visitors and users with our business partners, trusted
          affiliates and advertisers for the purposes outlined above.We may use
          third party service providers to help us operate our business and the
          Site or administer activities on our behalf, such as sending out
          newsletters or surveys. We may share your information with these third
          parties for those limited purposes provided that you have given us
          your permission.
        </p>
        <p>
          <br />
        </p>
        <p>
          We do not store credit card, debit card or payment processing details
          on our server. We never give your details to third parties to use your
          data to enable them to provide you with information regarding
          unrelated goods or services.
        </p>
        <p>
          <br />
        </p>
        <p>Third party websites</p>
        <p>
          <br />
        </p>
        <p>
          Users may find advertising or other content on our Site that link to
          the sites and services of our partners, suppliers, advertisers,
          sponsors, licensors and other third parties. We do not control the
          content or links that appear on these sites and are not responsible
          for the practices employed by websites linked to or from our Site. In
          addition, these sites or services, including their content and links,
          may be constantly changing. These sites and services may have their
          own privacy policies and customer service policies. Browsing and
          interaction on any other website, including websites which have a link
          to our Site, is subject to that website&rsquo;s own terms and
          policies.
        </p>
        <p>
          <br />
        </p>
        <p>Changes to this privacy policy</p>
        <p>
          <br />
        </p>
        <p>
          Our company has the discretion to update this privacy policy at any
          time. When we do, revise the updated date at the bottom of this page,.
          We encourage Users to frequently check this page for any changes to
          stay informed about how we are helping to protect the personal
          information we collect. You acknowledge and agree that it is your
          responsibility to review this privacy policy periodically and become
          aware of modifications.
        </p>
        <p>
          <br />
        </p>
        <p>Your acceptance of these terms</p>
        <p>
          <br />
        </p>
        <p>
          By using this Site, you signify your acceptance of this policy and
          terms of service. If you do not agree to this policy, please do not
          use our Site. Your continued use of the Site following the posting of
          changes to this policy will be deemed your acceptance of those
          changes.
        </p>
        <p>
          <br />
        </p>
        <p>Contacting us</p>
        <p>
          <br />
        </p>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <p>
          <br />
        </p>
        <p>
          71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
        </p>
      </Paragraph>
      <CopyRight />
    </div>
  );
}

export default Privacy;
