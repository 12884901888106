export let Essentialvitamins =
  "https://res.cloudinary.com/dsix5nojk/image/upload/v1706481281/pexels-polina-tankilevitch-3873209_gp4vry.jpg";
export let Protein =
  "https://res.cloudinary.com/dsix5nojk/image/upload/v1706481279/pexels-darina-belonogova-8004560_clcrpm.jpg";
export let payment =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838910/payment_mkel3w.jpg";
export let profile =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838912/vecteezy_user-avatar-line-style__x94mjc.jpg";
export let EmptyCart =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838909/Cart_bk4xgl.jpg";
export let customerReview =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838911/Review_bm6chw.jpg";
export let heroImage = "";
