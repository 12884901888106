// // import React from "react";
// // import Image from "../../Assets/Images/suplm.jpg";

// // import { Card, Flex, Typography } from "antd";
// // const cardStyle = {
// //   margin: "30px",
// //   width: "100%",
// // };
// // const imgStyle = {
// //   display: "block",
// //   width: 773,
// //   height: "100%",
// // };

// // const ContentWithImage = () => {
// //   return (
// //     <Card
// //       hoverable
// //       style={cardStyle}
// //       styles={{
// //         body: {
// //           padding: 0,
// //           overflow: "hidden",
// //         },
// //       }}
// //     >
// //       <Flex justify="space-between">
// //         <img alt="avatar" src={Image} style={imgStyle} />
// //         <Flex
// //           vertical
// //           align="center"
// //           justify="space-between"
// //           style={{
// //             padding: 32,
// //             borderRadius: "10px",
// //           }}
// //         >
// //           <Typography.Title level={4}>
// //             <h1>Why Choose Natural Supplements?</h1>

// //             <p>
// //               In a world filled with synthetic products, natural supplements
// //               stand out for their purity and effectiveness. Nature has provided
// //               us with a wealth of ingredients that have been used for centuries
// //               in traditional medicine. At Divine Wellbeing, we believe in the
// //               inherent wisdom of nature and strive to bring you the best of what
// //               it has to offer.
// //             </p>
// //             <q>People are just as happy as they make up their minds to be.</q>
// //             <h2 style={{ textAlign: "end" }}>Abraham Lincoln</h2>
// //           </Typography.Title>
// //         </Flex>
// //       </Flex>
// //     </Card>
// //   );
// // };

// // export default ContentWithImage;

// import React from "react";
 import Image from "../../Assets/Images/suplm.jpg";
// import { Row, Typography } from "antd";




// const ContentWithImage = () => {


//   return (
// <div  style={{ display: "flex", justifyContent: "space-between", padding: 30,  marginTop: 30, width: "100%"}} >

//   <div >
//     <img alt="avatar" src={Image}  />
//   </div>

//   <div>
   
//        <Typography.Title level={4}>

//             <h1>Why Choose Natural Supplements?</h1>
//             <p>
//               In a world filled with synthetic products, natural supplements
//               stand out for their purity and effectiveness. Nature has provided
//               us with a wealth of ingredients that have been used for centuries
//               in traditional medicine. At Divine Wellbeing, we believe in the
//               inherent wisdom of nature and strive to bring you the best of what
//                it has to offer.
//              </p>
//             <q>People are just as happy as they make up their minds to be.</q>

//              <h2 style={{ textAlign: "end" }}>Abraham Lincoln</h2>
//           </Typography.Title>
//   </div>


  
// </div>

//   )
// };

// export default ContentWithImage;



import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ContentWithImage() {
  return (
    <Card style={{display: "flex", justifyContent: "space-between", padding: 30,  marginTop: 30, width: "100%"}}  sx={{ maxWidth: 700 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={Image}
          alt="green"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Why Choose Natural Supplements?
          </Typography>
          <Typography variant="body2" color="text.secondary">
      
            
               In a world filled with synthetic products, natural supplements
               stand out for their purity and effectiveness. Nature has provided
               us with a wealth of ingredients that have been used for centuries
               in traditional medicine. At Divine Wellbeing, we believe in the
               inherent wisdom of nature and strive to bring you the best of what
                it has to offer.
             <br />
             <q>People are just as happy as they make up their minds to be.</q>

              <h2 style={{ textAlign: "end" }}>Abraham Lincoln</h2>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
