import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from '@mui/system'
import { Col, Row } from "antd";
import Loading from "../loading/Loading";

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_FETCH_PRODUCT}`
      );
      console.log(response);
      const products = await response.data.slice(0, 4);
      setProducts(products);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return  ( <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", paddingLeft: 10, paddingBottom: 20 }}>
    <Loading /><Loading />
</Container >)
  }

  return (
    <div className="featured-products">
      <h2>Featured Products</h2>
      <Row>
        {products.map((product) => (
          <Col span={6} key={product.id}>
            <img
              style={{ width: "100%" }}
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <div className="product-details">
              <h3 className="product-name">{product.name}</h3>
              <p className="product-price">${product.price}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeaturedProducts;
