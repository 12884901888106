// AboutUs.js

import React from "react";
import "./aboutUs.css";
import CopyRight from "../../Components/CopyRight/CopyRight";
const AboutUs = () => {
  return (
    <div className="container">
      <div>
        <h1>About us</h1>
      </div>

      <div class="about-section fade-in">
        <div class="about-text">
          <p>
            Welcome to Divine Wellbeing, where we redefine the standard for
            premium nutrition to empower you on your journey to a healthier,
            stronger, and more vibrant life. At Divine Wellbeing, we believe in
            the transformative power of science-backed supplements to elevate
            your performance and unlock your full potential.
          </p>
        </div>
      </div>

      <div class="about-section fade-in">
        <div class="about-text">
          <h2>Our Mission</h2>
          <p>
            Embark on a wellness journey with Divine Wellbeing - a
            mission-driven brand committed to providing you with the highest
            quality supplements that support your unique health and fitness
            goals. Whether you're a seasoned athlete or just starting, our goal
            is to inspire and guide you toward optimal well-being.
          </p>
        </div>
      </div>

      <div class="about-section fade-in">
        <div class="about-text">
          <h2>Quality Assurance</h2>
          <p>
            Trust is the cornerstone of our relationship with you. Every product
            at Divine Wellbeing undergoes stringent testing and quality
            assurance processes. We partner with leading laboratories to
            guarantee that our supplements meet the most rigorous industry
            standards for purity, potency, and effectiveness. Innovation and
            Science: Fuel your success with cutting-edge formulations crafted by
            our team of experts who are passionate about innovation and
            scientific excellence. We remain at the forefront of nutritional
            science, delivering products that reflect the latest advancements.
            Each blend is carefully curated to provide you with a powerful
            combination of ingredients to support peak performance and rapid
            recovery.
          </p>
        </div>
      </div>

      <div class="about-section fade-in">
        <div class="about-text">
          <h2>Transparency</h2>
          <p>
            We believe in open communication and transparency. Divine Wellbeing
            provides comprehensive information about our products, including
            ingredient details, sources, and the scientific rationale behind
            their effectiveness. Your body deserves the best, and we're
            committed to helping you make informed decisions about what you put
            into it.
          </p>
        </div>
      </div>

      <div class="about-section fade-in">
        <div class="about-text">
          <h2>Customer Satisfaction</h2>
          <p>
            Your satisfaction is our top priority. Divine Wellbeing is dedicated
            to providing exceptional customer service and support throughout
            your wellness journey. Whether you seek guidance on products, need
            assistance with your fitness regimen, or have questions, our
            knowledgeable team is here to offer personalized support. Join us in
            embracing a new era of health and fitness. At Divine Wellbeing,
            we're not just a supplement company we're your partners in wellness.
            Elevate your performance, embrace your potential, and experience the
            transformative difference with our premium supplements. Welcome to a
            life redefined through optimal nutrition and well-being!
          </p>
        </div>
      </div>

      {/* footer */}
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </div>
  );
};

export default AboutUs;
