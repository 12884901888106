// src/components/ContactUs.js

import React, { useState } from "react";
// import './ContactUs'
import "./contact_us.css";
import CopyRight from "../../Components/CopyRight/CopyRight";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Add your logic to handle form submission (e.g., send data to a server)
  //     console.log('Form submitted:', formData);
  //     // Reset form after submission
  //     setFormData({
  //         name: '',
  //         email: '',
  //         message: '',
  //     });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data (add your validation logic here)
    if (!formData.name || !formData.email || !formData.message) {
      alert("Please fill in all fields.");
      return;
    }

    try {
      // Assuming you have an API endpoint to handle form submissions
      const response = await fetch("https://example.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        // Add any additional logic after successful submission
      } else {
        console.error("Form submission failed");
        // Handle error if needed
      }
    } catch (error) {
      console.error("Error submitting form", error);
      // Handle error if needed
    }
  };

  return (
    <div className="container">
      <h1 className="form-title">Contact Us</h1>
      <div className="contactUs">
        <h3>
          Email:
          <br />
          <a href="mailto:customercare@divinewellbeing.co.uk">
            customercare@divinewellbeing.co.uk
          </a>
        </h3>
        <h3>Phone No:+91765465343</h3>
        <h3>
          Address:
          <br />
          71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
        </h3>
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-field">
          <label htmlFor="name"></label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="email"></label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="message"></label>
          <textarea
            id="message"
            name="message"
            placeholder="Message..."
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-field">
          <button type="submit">Submit</button>
        </div>
      </form>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </div>
  );
};

export default ContactUs;
