import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import Cart from "./Pages/Cart/Cart";
import ProductDetail from "./Pages/Detail/ProductDetail";
import SingleCategory from "./SingleCategory/SingleCategory";
import MobileNavigation from "./Navigation/MobileNavigation";
import DesktopNavigation from "./Navigation/DesktopNavigation";
import Wishlist from "./Pages/WhisList/Wishlist";
import PaymentSuccess from "./Pages/Payment/PaymentSuccess";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckoutForm from "./Components/Checkout/CheckoutForm";
import UpdateDetails from "./Pages/Update_User/UpdateDetails";
import ForgotPasswordForm from "./Auth/ForgotPassword/ForgotPasswordForm";
import AddNewPassword from "./Auth/ForgotPassword/AddNewPassword";
import AdminLogin from "./Admin/Auth/Login/AdminLogin";
import AdminRegister from "./Admin/Auth/Register/AdminRegister";
import AdminHomePage from "./Admin/Pages/AdminHomePage";
import SingleUserPage from "./Admin/Pages/SingleUserPage";
import SingleProduct from "./Admin/Pages/SingleProduct";
import ContactUs from "./Pages/Contact_us/contact_us";
import AboutUs from "./Pages/About_us/aboutUs";
import PageNotFound from "./Pages/404/404";
import Shipment from "./Pages/Policies/Shipment";
import Mollie from "./Pages/Mollie";
import Privacy from "./Pages/Policies/Privacy";
import Return from "./Pages/Policies/Return";
import Terms from "./Pages/Policies/Terms";

function App() {
  return (
    <>
      <ToastContainer
        toastClassName="toastContainerBox"
        transition={Flip}
        position="top-center"
      />
      <Router>
        <DesktopNavigation />
        <div className="margin">
          <Routes>
            {/*User Routes  */}
            <Route path="/" index element={<HomePage />} />
            <Route path="/test" element={<Mollie />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/Detail/type/:cat/:id" element={<ProductDetail />} />
            <Route path="product/type/:cat" element={<SingleCategory />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/update" element={<UpdateDetails />} />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/forgotpassword" element={<ForgotPasswordForm />} />
            <Route path="/user/reset/:id/:token" element={<AddNewPassword />} />
            <Route path="/shipment" element={<Shipment />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="return" element={<Return />} />
            <Route path="terms" element={<Terms />} />
            <Route path="/*" element={<PageNotFound />} />
            {/* Admin Routes */}
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/register" element={<AdminRegister />} />
            <Route path="/admin/home" element={<AdminHomePage />} />
            <Route path="/admin/home/user/:id" element={<SingleUserPage />} />
            <Route
              path="/admin/home/product/:type/:id"
              element={<SingleProduct />}
            />
          </Routes>
        </div>
        <MobileNavigation />
      </Router>
    </>
  );
}
export default App;
