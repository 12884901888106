import { Protein, Essentialvitamins } from '../Assets/Images/Image';
const data = [
    {
        img: Protein,
        name: "Proteins"
    },
    {
        img: Essentialvitamins,
        name: "EssentialVitamins"
    },

]
export default data